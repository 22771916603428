<template>
  <div class="w-100">
      <b-card-code class="m-0 pb-2 w-100 text-center">
        <h2 class="pb-2" style="color: var(--mhc-dark)">Contact Us</h2>
        <h3 class="pb-2">Get in touch</h3>

        <p class="">Emails:</p>
        <div class="font-weight-light">
            <p>Press/media enquiries: 
                <a href="mailto:press@mentalhealthcheck.org" class="mhc-link-blue-color">press@mentalhealthcheck.org</a>
            </p>
            <p>Partnership enquiries: 
                <a href="mailto:partnerships@mentalcheck.org" class="mhc-link-blue-color">partnerships@mentalcheck.org</a>
            </p>
            <p>Technical support: 
                <a href="mailto:technical@mentalhealthcheck.org" class="mhc-link-blue-color">technical@mentalhealthcheck.org</a>
            </p>
            <p>For employers: 
                <a href="mailto:employer@mentalhealthcheck.org" class="mhc-link-blue-color">employer@mentalhealthcheck.org</a>
            </p>
            <p>For mental health professional/practitioner: 
                <a href="mailto:mhp@mentalhealthcheck.org" class="mhc-link-blue-color">mhp@mentalhealthcheck.org</a>
            </p>
            <p>Anything else: 
                <a href="mailto:hello@mentalhealthcheck.org" class="mhc-link-blue-color">hello@mentalhealthcheck.org</a>
            </p>
        </div>
        
      </b-card-code>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
  },
};

</script>
<style>
    .mhc-link-blue-color {
        color: var(--mhc-dark);
    }
</style>
